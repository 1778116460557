.App {
  position: relative;
  max-width: 100vw;
  overflow-x: hidden;

  .button button {
    // reset
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    font-size: inherit;
    // custom
    text-transform: uppercase;
    font-weight: bold;
    color: #4c4c4c;
    margin: calc(0.5em - 1px) auto;
    padding: 0.125em 1em;
    line-height: 1.5em;
    border: 1px solid #4c4c4c;
    border-radius: 1.5em;
    cursor: pointer;
    transition: background 0.3s cubic-bezier(0.77, 0, 0.175, 1),
      color 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .button.inactive {
    opacity: 0.35;
  }

  .button button:focus {
    outline: 0; // maybe update border style instead?
  }

  .button button:hover {
    background: #4c4c4c;
    color: white;
  }

  .button.inactive button:hover {
    background-color: transparent;
    color: #4c4c4c;
  }
}

@media screen and (max-width: 812px) {
  .App {
    font-size: 0.85rem;
  }
}
