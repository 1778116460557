@import '../../variables.scss';

.toHide {
  display: none;
}

.DataTable {
  background-color: $light-gray;
  padding: 1.5em;
  h2 {
    font-size: 1em;
    font-weight: 600;
    margin: 0 0 0 1.5em;
  }
  .table-wrapper {
    border: 1px solid $dark-gray;
    border-radius: 10px;
    margin-top: 1em;
  }
  table {
    background-color: white;
    border-collapse: collapse;
    border-radius: 10px;
    border: none;
    table-layout: fixed;
    text-align: left;
    width: 100%;

    .border-top-bottom {
      border-top: 1px solid $dark-gray;
      border-bottom: 1px solid $dark-gray;
    }

    th {
      cursor: pointer;
      font-weight: bold;
      padding: 1em;
      text-decoration: underline;
      text-transform: uppercase;

      img {
        height: 0.4em;
        margin-left: 0.4em;
      }
    }

    .header-wrapper {
      display: flex;
      align-items: center;
    }

    .table-subheader {
      background-color: $light-gray;
      border-right: 1px solid $dark-gray;
      padding-left: 1em;
    }

    .table-subheader.country {
      font-weight: 600;
    }

    .tooltip-hover-icon {
      cursor: pointer;
      margin-left: 2px;
      transform: translateY(3px);
    }

    .agreements-header {
      border: none;
      font-size: 0.8em;
      font-weight: 600;
      line-height: 1.5em;
      text-transform: uppercase;
      padding: 9px 1.4em 2px 1.4em;
    }

    .issuer-cell {
      color: white;
      border: 2px solid white;
      padding: 0 1em;
      &.imf-conditional {
        background-color: $imf-conditional-color;
      }
      &.imf-unconditional {
        background-color: $imf-unconditional-color;
      }
      &.rfa {
        background-color: $rfa-color;
      }
      &.swap {
        background-color: $swap-color;
      }
    }

    .agreement-label {
      font-size: 0.9em;
      font-weight: 600;
      padding-right: 1em;
      text-align: right;
      text-transform: uppercase;
      vertical-align: middle;
    }

    .center-text {
      text-align: center;
    }

    .spacer {
      grid-column: 1 / -1;
      height: 1em;
    }

    .line {
      background: $dark-gray;
      height: 1px;
    }

    .no-border {
      border-right: none !important;
    }
  }
}

.CapacityTable {
  padding: 1em 1.5em 1.5em;

  h2 {
    font-size: 1.8em;
    margin: 0 0 0 4px;
  }
  table {
    td {
      border: 1px solid $dark-gray;
      padding: 0 1em;
    }
    td.country-header {
      font-weight: 600;
    }
    tr:nth-child(even) {
      background-color: $light-gray;
    }
    tr:last-child td {
      border-bottom: none;
    }
    tr:last-child td:first-child {
      border-left: none;
    }
    tr:last-child td:last-child {
      border-right: none;
    }
    .right-align {
      text-align: right;
    }
  }
}
