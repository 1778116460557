.Navigation {
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;

  .topLevel {
    background: black;
    display: flex;

    .title {
      text-align: left;
      flex: 1;
      min-width: 21em;
      padding: 0.5em 2em;
      color: white;

      h1,
      h2 {
        margin: 0;
      }
      h1 {
        font-size: 1.25em;
      }
      h2 {
        margin-top: -0.25em;
        margin-bottom: 0.25em;
        font-size: 0.75em;
        line-height: 1.25em;
        font-weight: 600;
      }
    }

    .spacer {
      flex: 1 0;
    }
  }

  .Selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-transform: uppercase;

    .option {
      cursor: pointer;
    }
  }

  .Selector.view {
    flex: 1 auto;
    background: black;
    padding-top: 0.5em;
    margin-top: auto;

    .option {
      color: white;
      border-radius: 0.5em 0.5em 0 0;
      font-weight: 600;
      padding: 0.25em 1em 0.4em 1em;
      margin: 0 0.5em;
    }
    .option.active {
      color: black;
      background: #ebebe6;
    }
    .option.view {
      background: rgba(255, 255, 255, 0.15);
    }
    .option.view.active {
      background: rgba(255, 255, 255, 1);
    }
  }
}

@media screen and (max-width: 812px) {
  .Navigation .topLevel .spacer {
    flex: 0;
  }
}

@media screen and (max-width: 640px) {
  .Navigation {
    .topLevel {
      flex-direction: column;
      font-size: 1.5em;

      .title {
        min-width: unset;
        padding-left: 1em;

        h2 {
          width: 26em;
        }

        h2 {
          margin: 0;
        }
      }
      .Selector.view {
        margin: 0 1em;
        display: flex;

        .option {
          flex: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Navigation {
    font-size: 0.75em;
  }
}
