@import '../../variables.scss';

.Filters {
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  left: 1.5em;
  padding: 16px;
  top: 1.5em;
  width: 200px;

  .header {
    font-weight: 600;
    margin: 0 0 8px 0;
  }

  .description {
    font-size: 0.85rem;

  }

  .filterSubhead {
    font-weight: 600;
    margin: 16px 0 8px 0;
  }

  .filterOptions {
    display: grid;
    grid-template-columns: 16px auto auto;
    column-gap: 6px;
    justify-items: right;
    row-gap: 8px;
  }

  .filterLabel {
    font-weight: 600;
    font-size: 0.85rem;
    place-self: center left;
  }

  .ecb-icon{
    width: fit-content;
    max-height: 1.2em;
    background-color: #f4cf3d;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .isoIcon {
    place-self: center;
  }

  .toggle-swap {
    transform: translateY(60%);
  }

  .toggle-imf-conditional.react-toggle--checked .react-toggle-track {
    background-color: $imf-conditional-color;
  }
  .toggle-imf-unconditional.react-toggle--checked .react-toggle-track {
    background-color: $imf-unconditional-color;
  }
  .toggle-rfa.react-toggle--checked .react-toggle-track {
    background-color: $rfa-color;
  }
  .toggle-swap.react-toggle--checked .react-toggle-track {
    background-color: $swap-color;
  }

  select {
    font-family: 'Source Sans Pro', sans-serif;
    margin-top: 16px;
    min-height: 2em;
    width: 100%;
  }
}
