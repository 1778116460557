.AgreementsTimeseries {
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 20% 60%;
  padding: 1em 1.5em;
  .instruction {
    font-weight: bold;
  }
  .chart {
    position: relative;
    svg text {
      dominant-baseline: text-before-edge;
      text-anchor: middle;
      text-anchor: middle;
    }
    .tick {
      stroke: #000;
    }
    .tick-text {
      fill: #707070;
    }
    .totals-text {
      fill: white;
      font-size: 11px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  .AgreementsTooltip {
    position: absolute;
    padding: 0.5em;
    background-color: #fdfdfc;
    border-radius: 5px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
  }
}
