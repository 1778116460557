.Summary {
  position: relative;
  height: 3em;
  background: white;
  color: white;
  font-weight: bold;
  display: flex;

  .section {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    font-size: 1.5em;
    line-height: 1em;
    padding: 0.2em 0.5em;
    white-space: nowrap;
    overflow: hidden;

    &.collapsed {
      padding: 0;
    }

    &.animated {
      width: 0;
      transition: width 0.45s cubic-bezier(0.77, 0, 0.175, 1),
        background 0.45s cubic-bezier(0.77, 0, 0.175, 1),
        padding 0.45s cubic-bezier(0.77, 0, 0.175, 1);
    }

    .label {
      vertical-align: top;
      text-transform: uppercase;
      font-size: 0.36em;
      line-height: 1.5em;

      span {
        margin-left: 0.5em;
      }
    }

    .value {
      display: flex;
      align-items: flex-end;

      .number {
        display: inline-block;
        transform: translate(0, 0.04em);
      }

      .units-wrapper {
        font-size: 0.5em;
        line-height: 1em;
        margin-left: 0.5em;
      }

      .units {
        text-transform: uppercase;
      }

      .label {
        width: 7em;
        white-space: normal;
        display: inline-block;
        margin-left: 0.5em;
        margin-top: 0.2em;
        line-height: 1.2em;
      }
    }
  }
  .tooltip.section {
    position: absolute;
    z-index: 10;
    transform: translate(-60%, -110%);
    border-radius: 0.25em;

    .label {
      width: unset;
      margin-bottom: 0.1em;
    }
    .value .label {
      margin-top: 0.1em;
      margin-bottom: 0;
      margin-right: 1em;
    }
  }
}

@media screen and (max-width: 812px) {
  .Summary {
    .section {
      padding: 0.2em 0.25em;

      .label {
        width: unset;
        margin: 0 0.25em;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .Summary {
    .section {
      font-size: 1.25em;

      .label {
        line-height: 1.25em;
        padding-top: 0;
      }
    }
  }
}
