.About {
  right: 0;
  padding: 0 2em;
  
  align-self: center;
  .links {
    display: flex;
    align-items: center;
    margin-top: -58px;
    img {
      margin-left: 1rem;
      margin-top: 5px;
      width: 1rem;
      height: 1rem;
    }
  }
  .about-link {
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
  }
  
  .AboutModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100;
    .about-content {
      width: 80%;
      margin: 32px auto;
      text-align: left;
    }
  }
}
.AboutContainer, .hubspot {
  margin: 32px auto;
  width: 65%;
  text-align: left;
  margin: auto;
}

li {
  padding-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .About {
    padding: 0.75em 1.5em;
    position: absolute;
  }
}

@media screen and (max-width: 414px) {
  .About {
    padding: 1em 1em;
  }
}
