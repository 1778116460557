.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(255, 255, 255, 0.5);
  z-index: 32;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);

  .container {
    position: relative;
    background: white;
    font-size: 1em;
    top: 0;
    line-height: 1.5em;
    border: 1px solid #cccccc;
    padding: 2.5em 1.5em;
    color: black;

    &.intro {
      top: calc(50% - 4.25em);
      width: 50vw;
      transform: translateY(-50%);
      margin: 4.25em auto;
      border-radius: 0.5em;
      padding: 1.5em;

      .close {
        display: none;
      }
    }

    &.about {
      font-weight: normal;
    }

    h1 {
      margin: 0;
      color: #cc0000;
      line-height: 1.1em;
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.5em;
      font-weight: bold;
      margin-right: 1em;
      margin-top: 1em;
      line-height: 0.25em;
      cursor: pointer;
      color: #4c4c4c;
      transition: color 0.45s cubic-bezier(0.77, 0, 0.175, 1);
    }
    .close:hover {
      color: #cc0000;
    }

    .content {
      text-align: left;
      margin-bottom: 1em;

      a {
        color: #cc0000;
      }
    }

    .button {
      text-align: center;
    }

    .button button {
      background: #4c4c4c;
      margin: 0;
      border: none;
      color: white;
    }

    .button button:hover {
      background: #cc0000;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
