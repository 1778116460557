.Header {
  align-items: center;
  background: #002d72;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.85rem;
  //justify-content: space-between;
  padding: 0.5em 2em;

  .logo {
    height: 3.3em;
  }

  .logo-small {
    height: 2.5em;
  }

  img {
    height: 100%;
  }

  .partners {
    display: flex;
    .logo {
      padding: 0 0 0 2em;
      
    }
  }
  .spacer {
    flex: 1 0;
  }
}

@media screen and (max-width: 640px) {
  .Header {
    padding: 1em 1.5em;
  }
}

@media screen and (max-width: 414px) {
  .Header {
    padding: 1em 1em;
  }
}
