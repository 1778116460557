@import '../../variables.scss';

$border-radius: 0.5em;

.tooltipWrapper {
  position: absolute;
  pointer-events: none;
  z-index: 31;
  animation: 0.1s cubic-bezier(0.77, 0, 0.175, 1) forwards fadeIn;

  .tooltip {
    background-color: #fdfdfc;
    border-radius: $border-radius;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
    margin: 12px 12px 16px 12px;

    &.countryTooltip {
      .tooltipGrid {
        align-items: center;
        display: grid;
        margin: 12px 0;
        gap: 8px;
      }
      svg text {
        dominant-baseline: text-before-edge;
        text-anchor: middle;
        text-anchor: middle;
      }
      .tick {
        stroke: #000;
      }
      .tick-text {
        fill: #707070;
      }
      .totals-text {
        fill: #000;
        font-size: 11px;
      }
      .swap-text {
        fill: #000;
        font-size: 11px;
        dominant-baseline: auto;
        tspan {
          dominant-baseline: auto;
        }
      }
    }

    .amountText {
      font-size: 13px;
      padding-left: 8px;
    }

    .partnerText {
      font-size: 13px;
      padding-right: 8px;
      text-align: right;
      max-width: 165px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.capacityTooltip {
      min-width: 200px;

      .accessTitle {
        margin-top: 1em;
      }
    }

    .tooltipTitle {
      font-weight: 600;
      max-width: 250px;
    }
    .content {
      padding: 0.75em;
    }
    .locked {
      color: #cc0000;
      font-size: 0.8em;
    }

    .content-stat .stat {
      font-weight: 600;
    }

    .content-stat.fade-stat {
      opacity: 50%;
    }

    .no-agreements {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      color: $dark-gray;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
