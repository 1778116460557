.sizeContainer {
  /**
		* For IE 6/7 only
		* Include this rule to trigger hasLayout and contain floats.
	*/
  *zoom: 1;
  /**/
  margin: 0 auto;
  position: relative;
  background: #cbf2ff;
  &:before,
  &:after {
    content: ' '; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }

  .mapContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .transformContainer {
      will-change: transform;
      -webkit-transform: translateZ(0);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transform-origin: 0 0 0;
    }

    &.transition .transformContainer {
      transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    }

    &.transition .stack path,
    &.transition path.shadow {
      opacity: 0;
    }
  }

  .lastUpdated {
    position: absolute;
    bottom: 0;
    margin: 8px 24px;
    font-size: 0.9em;
  }

  /* zoom */
  .controls {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    z-index: 20;

    .rippleButton {
      height: 26px;
      margin-bottom: 4px;
    }

    button {
      margin: 0 !important;
      padding: 0 !important;
      padding-bottom: 2px !important;
      width: 26px !important;
      height: 26px;
      border: 1px solid #666666;
      border-radius: 4px;
      line-height: 22px;
      font-size: 12pt;
      font-weight: bold;
      text-align: center;
      background: white;
      color: black;
      cursor: pointer;
      outline: none;
      img {
        width: 20px;
        margin: 2px 2.5px;
      }
    }

    button.inactive {
      background: #c8c8c6 !important;
    }
  }

  .colorScale {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    padding-top: calc(1em - 3px);
    background-color: rgba(203, 242, 255, 0.5);
    z-index: 20;
    border-bottom-left-radius: 0.5em;

    .tick {
      display: inline-block;
      // font-size: 0.7rem;
      font-size: 0.7em;
      text-align: center;
      .tickFill {
        width: 45px;
        height: 15px;
      }
      .tickLabel {
        margin-top: 2px;
      }
    }
  }
}

.Map {
  overflow: visible;

  .background {
    fill: #cccccc;
  }
}
